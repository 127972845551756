import Filler from '../../assets/MeetTheTeam//Logo_Filler.png';

// Co-Chair Pictures
import Triya from '../../assets/MeetTheTeam/Co-Chairs/Triya_Co-Chair.jpg';
import Luana from '../../assets/MeetTheTeam/Co-Chairs/Luana_Co-Chair.jpg';
import Bohina from '../../assets/MeetTheTeam/Co-Chairs/Bohina_Co-Chair.png';
import Rumaisa from '../../assets/MeetTheTeam/Co-Chairs/Rumaisa_Co-Chair.jpg';

// IT Team Pictures
import Julia from '../../assets/MeetTheTeam/IT/Julia_ITDirector.jpg';
import Dimitra from '../../assets/MeetTheTeam/IT/Dimitra_ITExecutive.jpg';
import Aryan from '../../assets/MeetTheTeam/IT/Aryan_Kansagara_IT_Executive.jpg';
import Joanne from '../../assets/MeetTheTeam/IT/Joanne_ITExecutive.jpg';
import Rayyan from '../../assets/MeetTheTeam/IT/Rayyan_ITExecutive.jpg';
import Natalie from '../../assets/MeetTheTeam/IT/Natalie_ITExecutive.jpg';
import Madeleine from '../../assets/MeetTheTeam/IT/Madeleine_ITExecutive.jpg';
import Trinity from '../../assets/MeetTheTeam/IT/Trinity_ITExecutive .jpg';
import Hailey from '../../assets/MeetTheTeam/IT/Hailey_ITExecutive.jpg';
import Khushi from '../../assets/MeetTheTeam/IT/Khushi_ITExecutive.jpg';

// Logistics Team Pictures
import Sabriha from '../../assets/MeetTheTeam/Logistics/Sabriha_LogisticsDirector.png';
import Misheel from '../../assets/MeetTheTeam/Logistics/Misheel_LogisticsExecutive.png';
import James from '../../assets/MeetTheTeam/Logistics/James_LogisticsExec.jpg';
import Assem from '../../assets/MeetTheTeam/Logistics/Assem_LogisticsExec.jpg';  
import Aditi from '../../assets/MeetTheTeam/Logistics/Aditi_LogisticsExecutive.jpg';  
import Kristin from '../../assets/MeetTheTeam/Logistics/Kristin_LogisticsExecutive.jpg';
import Mahnsi from '../../assets/MeetTheTeam/Logistics/Mahnsi_LogisticsExecutive.jpg';
import Vibitha from '../../assets/MeetTheTeam/Logistics/Vibitha_LogisticsExecutive.jpg';

// Marketing Team Pictures
import Ning from '../../assets/MeetTheTeam/Marketing/Ning_MarketingDirector.jpg';
import Katsa from '../../assets/MeetTheTeam/Marketing/Katsa_Marketing.jpg';
import Rodiat from '../../assets/MeetTheTeam/Marketing/Rodiat_Marketing.jpg';
import Ann from '../../assets/MeetTheTeam/Marketing/Ann_Marketing.jpg';
import Basak from '../../assets/MeetTheTeam/Marketing/Basak_Marketing.jpg';
import Kelly from '../../assets/MeetTheTeam/Marketing/Kelly_CreativeMedia.jpg';
import Eloise from '../../assets/MeetTheTeam/Marketing/Eloise_CreativeMedia.png';
import Harper from '../../assets/MeetTheTeam/Marketing/Harper - Social Med & Outreach.jpg';


// Sponsorship Team Pictures
import Sidney from '../../assets/MeetTheTeam/Sponsorship/Sidney_SponsorshipDirector.jpg';
import Charlene from '../../assets/MeetTheTeam/Sponsorship/Charlene_Sponshorship Executive.jpg';
import Anagha from '../../assets/MeetTheTeam/Sponsorship/Anagha_SponsorshipExecutive.jpg';
import Anusha from '../../assets/MeetTheTeam/Sponsorship/Anusha_SponsorshipExecutive.jpg';
import Brianna from '../../assets/MeetTheTeam/Sponsorship/Brianna_SponsorshipExecutive.jpg';

const teams = {
  coChairs: [
    { name: 'Triya Augustine', title: 'Co-Chair', image: Triya, linkedin: "https://www.linkedin.com/in/triya-augustine/"},
    { name: 'Luana Ribeiro Madeira', title: 'Co-Chair', image: Luana, linkedin: "https://www.linkedin.com/in/luanamadeira"},
    { name: 'Bohina Manochehrzadeh', title: 'Co-Chair', image: Bohina, linkedin: "https://www.linkedin.com/in/bohina-m/"},
    { name: 'Rumaisa Nawar', title: 'Co-Chair', image: Rumaisa, linkedin: "https://www.linkedin.com/in/rumaisanawar/"},
  ],
  it1: [
    { name: 'Julia Da Silva', title: 'IT Director', image: Julia, linkedin: "https://www.linkedin.com/in/juliapereiradasilva/"},
    { name: 'Dimitra Balasingam', title: 'IT Designer', image: Dimitra, linkedin: "https://www.linkedin.com/in/dimitra-balasingam-116684239?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"},
    { name: 'Aryan Kansagara', title: 'IT Executive', image: Aryan, linkedin: "https://www.linkedin.com/in/aryan-kansagara/"},
    { name: 'Joanne Wijetunga', title: 'IT Executive', image: Joanne, linkedin: "https://www.linkedin.com/in/joanne-wijetunga04/"},
    { name: 'Rayyan Ahmed', title: 'IT Executive', image: Rayyan, linkedin: "https://www.linkedin.com/in/rayyan-ahmed/"},
  ],
  it2: [
    { name: 'Natalie Lewis', title: 'IT Executive', image: Natalie, linkedin: "https://www.linkedin.com/in/natalie--lewis/"},
    { name: 'Madeleine Falcon', title: 'IT Executive', image: Madeleine, linkedin: "https://www.linkedin.com/in/madeleine-falcon-phan/" },
    { name: 'Trinity Mae Robel', title: 'IT Executive', image: Trinity, linkedin: "https://www.linkedin.com/in/trinity-robel/"},
    { name: 'Khushi Jain', title: 'IT Executive', image: Khushi, linkedin: "https://www.linkedin.com/in/khushijain01"},
    { name: 'Hyunji Yun (Hailey)', title: 'IT Executive', image: Hailey, linkedin: "https://www.linkedin.com/in/haileyhjyun/" },
  ],
  logistics1: [
    { name: 'Sabriha Amin', title: 'Logistics Director', image: Sabriha, linkedin: "https://www.linkedin.com/in/sabrihaamin/"  },
    { name: 'Misheel Tuguldur', title: 'Logistics Executive', image:  Misheel, linkedin: "https://www.linkedin.com/in/misheel-tuguldur?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" },
    { name: 'James Liang', title: 'Logistics Executive', image: James, linkedin: "https://www.linkedin.com/in/james-liang-/"  },
    { name: 'Assem Malgazhdarova', title: 'Logistics Executive', image: Assem, linkedin: "https://www.linkedin.com/in/assemmalgazhdarova/"  },
    { name: 'Aditi Patel', title: 'Logistics Executive', image: Aditi, linkedin: "https://www.linkedin.com/in/aditi-patel-43452927a/"  },
  ],
  logistics2: [
    { name: 'Kristin Obinque Villena', title: 'Logistics Executive', image: Kristin, linkedin: "https://www.linkedin.com/in/kristin-villena-456950310/"  },
    { name: 'Mahnsi Ruparelia', title: 'Logistics Executive', image:  Mahnsi, linkedin: "https://www.linkedin.com/in/mahnsi/" },
    { name: 'Vibitha SureshKumar', title: 'Logistics Executive', image: Vibitha, linkedin: "http://linkedin.com/in/vibitha-sk"  },
    { name: 'Asma Hashar', title: 'Logistics Executive', image: Filler, linkedin: "https://www.linkedin.com/company/ellehacks/posts/?feedView=all"  },
    { name: 'Kimli Nguyen (Kim)', title: 'Logistics Executive', image: Filler, linkedin: "https://www.linkedin.com/company/ellehacks/posts/?feedView=all"  },
  ],
  marketing1: [
    { name: 'Wan Ning Ma', title: 'Marketing Director', image: Ning, linkedin: "https://www.linkedin.com/in/wnmwnm/" },
    { name: 'Katsa Bejemil', title: 'Social Media Exec', image: Katsa, linkedin: "https://www.linkedin.com/in/katsa-bejemil-a8599b243/"  },
    { name: 'Rodiat Adeoye', title: 'Social Media Exec', image: Rodiat, linkedin: "https://www.linkedin.com/in/rodiat-adeoye/"  },
    { name: 'Ann Margarette Orita', title: 'Social Media Exec', image: Ann, linkedin: "https://www.linkedin.com/company/ellehacks/posts/?feedView=all" },
    { name: 'Basak Babadagi', title: 'Social Media Exec', image: Basak, linkedin: "https://www.linkedin.com/company/ellehacks/posts/?feedView=all"  },
  ],
  marketing2: [
    { name: 'Huyen Le (Harper)', title: 'Social Media Exec', image: Harper, linkedin: "https://www.linkedin.com/in/huyenttle?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"},
    { name: 'Kelly Mark', title: 'Creative Media', image: Kelly, linkedin: "https://www.linkedin.com/company/ellehacks/posts/?feedView=all" },
    { name: 'Phuong Anh Nguyen (Eloise)', title: 'Creative Media', image: Eloise, linkedin: "https://www.linkedin.com/company/ellehacks/posts/?feedView=all" },
    { name: 'Heba El Dassouki', title: 'Creative Media', image: Filler, linkedin: "https://www.linkedin.com/in/heba-dassouki-9706b528b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"  },
  ],
  sponsorship1: [
    { name: 'Yuqian Liu (Sidney)', title: 'Sponsorship Director', image: Sidney, linkedin: "https://www.linkedin.com/in/sidneyliu01/"  },
    { name: 'Charlene Noye', title: 'Sponsorship Executive', image: Charlene, linkedin: "https://www.linkedin.com/in/charlene-noye-a48b24265/" },
    { name: 'Anagha Koroth', title: 'Sponsorship Executive', image: Anagha, linkedin: "https://www.linkedin.com/company/ellehacks/posts/?feedView=all" },
  ],

  sponsorship2: [
    { name: 'Anusha Masroor', title: 'Sponsorship Executive', image: Anusha, linkedin: "https://www.linkedin.com/company/ellehacks/posts/?feedView=all"  },
    { name: 'Brianna Nguyen', title: 'Sponsorship Executive', image: Brianna, linkedin: "https://www.linkedin.com/company/ellehacks/posts/?feedView=all" },
    { name: 'Pramitha Raja Madhukar', title: 'Sponsorship Executive', image: Filler, linkedin: "https://www.linkedin.com/company/ellehacks/posts/?feedView=all"  },
  ],
};
export default teams;
